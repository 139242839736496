import { motion } from "framer-motion"; // Asegúrate de tener instalado framer-motion
import { debounce } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath";
import MarkdownPreview from "./MarkdownPreview";
import SEOEssayCreator from "./seo/SEOEssayCreator";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";

const apiUrl = process.env.REACT_APP_API_URL;

const texts = {
  es: {
    titleApp: "🖊️ Creador de Ensayos con Inteligencia Artificial",
    path: "Creador de Ensayos",
    title:
      "Creador de Ensayos con IA | FotoExamen - Tu Asistente de Escritura Inteligente",
    metaDescription:
      "Crea ensayos, trabajos y tesis de alta calidad con la ayuda de nuestra IA. FotoExamen te ofrece un asistente de escritura inteligente para mejorar tus trabajos académicos.",
    steps: [
      "¿Sobre qué te gustaría que escribiéramos?",
      "¿Cuánto debería ser?",
      "¿Cómo debería sonar?",
      "¿Necesitas referencias en tu trabajo?",
      "Temas sugeridos para tu ensayo",
      "Tu ensayo generado:",
    ],
    loading: "Generando temas sugeridos...",
    generateEssay: "Generar Ensayo",
    createAnotherEssay: "Crear otro ensayo",
    previous: "← Anterior",
    next: "Siguiente →",
    addNewTopic: "Añadir nuevo tema",
    remove: "Eliminar",
    suggestedTopic: "Tema sugerido:",
    exampleTone: "Ejemplo de tono",
    citationStyleInfo: "Sobre el estilo",
    noConnection:
      "No se pudo establecer la conexión. Por favor, vuelve a intentarlo.",
    noTokenError: "No authentication token found.",
    placeholder: "Escribe tu tema aquí...",
    newTopic: "Nuevo tema",
    defaultTitle: "FotoExamen",
    promptInfo: "Tu indicación puede ser corta, larga o incluso ambigua. Deja los detalles en nuestras manos; elaboraremos un título que encapsule la esencia de tu concepto.",
    tones: {
      formal: "Formal",
      informative: "Informativo",
      objective: "Objetivo",
      analytical: "Analítico",
      persuasive: "Persuasivo",
      narrative: "Narrativo",
      descriptive: "Descriptivo",
      informal: "Informal"
    },
    toneExamples: {
      formal: "Nos complace presentar FotoExamen, una innovadora plataforma de evaluación que utiliza tecnología de reconocimiento facial para garantizar la integridad académica en entornos de aprendizaje a distancia.",
      informative: "FotoExamen es una herramienta de supervisión de exámenes en línea. Utiliza reconocimiento facial para verificar la identidad de los estudiantes y monitorear su comportamiento durante las pruebas.",
      objective: "FotoExamen, una plataforma de supervisión de exámenes en línea, emplea tecnología de reconocimiento facial para verificar la identidad de los estudiantes. Los usuarios reportan una reducción en los casos de fraude académico.",
      analytical: "Al examinar las capacidades de FotoExamen, se observa que su algoritmo de reconocimiento facial no solo verifica la identidad, sino que también monitorea el comportamiento durante el examen. Este enfoque dual podría resultar en un aumento significativo de la integridad académica en entornos de aprendizaje a distancia.",
      persuasive: "¿Te preocupa la integridad de tus exámenes en línea? Con FotoExamen, puedes estar tranquilo. Nuestra tecnología de punta garantiza que cada estudiante sea quien dice ser. ¿Por qué arriesgarte con soluciones menos efectivas cuando FotoExamen te ofrece seguridad total?",
      narrative: "Era un día como cualquier otro cuando la profesora García descubrió FotoExamen. Poco sabía que esta herramienta cambiaría por completo la forma en que realizaba sus evaluaciones en línea, llevándola a nuevos niveles de confianza en la integridad académica de sus estudiantes.",
      descriptive: "FotoExamen se presenta como un guardián digital, con una interfaz intuitiva y robusta que vigila atentamente. Sus algoritmos de reconocimiento facial, como ojos incansables, trabajan en silencio para asegurar que cada estudiante sea autenticado y monitoreado durante todo el examen.",
      informal: "¡Hola! ¿Cansado de preocuparte por trampas en los exámenes en línea? FotoExamen al rescate. Es como tener un súper profe virtual que se asegura de que todo el mundo juegue limpio. ¡Dile adiós a los dolores de cabeza de la evaluación a distancia!"
    },
    lengthPrompt: "Elija la longitud para su texto",
    lengthFormat: "Approx. {0} - {1} caracteres / {2} - {3} palabras",
    noThanks: "No, gracias",
    topicsInstructions: "Edita o elimina los temas que quieres incluir en tu ensayo. También puedes añadir nuevos temas.",
    generatingEssay: "Generando ensayo..."
  },
  en: {
    titleApp: "🖊️ Artificial Intelligence Essay Creator",
    path: "Essay Creator",
    title: "AI Essay Creator | PhotoExamAI - Your Intelligent Writing Assistant",
    metaDescription:
      "Create high-quality essays, assignments, and theses with the help of our AI. PhotoExamAI offers you an intelligent writing assistant to enhance your academic work.",
    steps: [
      "What would you like us to write about?",
      "How long should it be?",
      "How should it sound?",
      "Do you need references in your work?",
      "Suggested topics for your essay",
      "Your generated essay:",
    ],
    loading: "Generating suggested topics...",
    generateEssay: "Generate Essay",
    createAnotherEssay: "Create another essay",
    previous: "← Previous",
    next: "Next →",
    addNewTopic: "Add new topic",
    remove: "Remove",
    suggestedTopic: "Suggested topic:",
    exampleTone: "Example tone",
    citationStyleInfo: "About the style",
    noConnection: "Could not establish connection. Please try again.",
    noTokenError: "No authentication token found.",
    placeholder: "Write your topic here...",
    newTopic: "New topic",
    defaultTitle: "PhotoExamAI",
    promptInfo: "Your prompt can be short, long, or even ambiguous. Leave the details to us; we'll craft a title that captures the essence of your concept.",
    tones: {
      formal: "Formal",
      informative: "Informative",
      objective: "Objective",
      analytical: "Analytical",
      persuasive: "Persuasive",
      narrative: "Narrative",
      descriptive: "Descriptive",
      informal: "Informal"
    },
    toneExamples: {
      formal: "We are pleased to present PhotoExamAI, an innovative assessment platform that utilizes facial recognition technology to ensure academic integrity in distance learning environments.",
      informative: "PhotoExamAI is an online exam monitoring tool. It uses facial recognition to verify student identity and monitor their behavior during tests.",
      objective: "PhotoExamAI, an online exam supervision platform, employs facial recognition technology to verify student identity. Users report a reduction in cases of academic fraud.",
      analytical: "When examining PhotoExamAI's capabilities, it's observed that its facial recognition algorithm not only verifies identity but also monitors behavior during the exam. This dual approach could result in a significant increase in academic integrity in distance learning environments.",
      persuasive: "Worried about online exam integrity? With PhotoExamAI, you can rest easy. Our cutting-edge technology ensures each student is who they claim to be. Why risk less effective solutions when PhotoExamAI offers complete security?",
      narrative: "It was just another day when Professor Smith discovered PhotoExamAI. Little did she know this tool would completely change how she conducted her online assessments, taking her to new levels of confidence in her students' academic integrity.",
      descriptive: "PhotoExamAI stands as a digital guardian, with an intuitive and robust interface that vigilantly watches. Its facial recognition algorithms, like tireless eyes, work silently to ensure each student is authenticated and monitored throughout the exam.",
      informal: "Hey! Tired of worrying about cheating in online exams? PhotoExamAI to the rescue! It's like having a super virtual teacher making sure everyone plays fair. Say goodbye to distance assessment headaches!"
    },
    lengthPrompt: "Choose the length for your text",
    lengthFormat: "Approx. {0} - {1} characters / {2} - {3} words",
    noThanks: "No, thanks",
    topicsInstructions: "Edit or remove the topics you want to include in your essay. You can also add new topics.",
    generatingEssay: "Generating essay..."
  },
  it: {
    titleApp: "🖊️ Creatore di Saggio con Intelligenza Artificiale",
    path: "Creatore di Saggio",
    title:
      "Creatore di Saggio con IA | RisolviCompiti - Il tuo Assistente di Scrittura Intelligente",
    metaDescription:
      "Crea saggi, compiti e tesi di alta qualità con l'aiuto della nostra IA. RisolviCompiti ti offre un assistente di scrittura intelligente per migliorare i tuoi lavori accademici.",
    steps: [
      "Di cosa ti piacerebbe che scrivessimo?",
      "Quanto dovrebbe essere lungo?",
      "Come dovrebbe suonare?",
      "Hai bisogno di riferimenti nel tuo lavoro?",
      "Argomenti suggeriti per il tuo saggio",
      "Il tuo saggio generato:",
    ],
    loading: "Generazione argomenti suggeriti...",
    generateEssay: "Genera Saggio",
    createAnotherEssay: "Crea un altro saggio",
    previous: "← Precedente",
    next: "Successivo →",
    addNewTopic: "Aggiungi nuovo argomento",
    remove: "Rimuovi",
    suggestedTopic: "Argomento suggerito:",
    exampleTone: "Esempio di tono",
    citationStyleInfo: "Informazioni sullo stile",
    noConnection:
      "Impossibile stabilire la connessione. Per favore, riprova.",
    noTokenError: "No authentication token found.",
    placeholder: "Scrivi il tuo argomento qui...",
    newTopic: "Nuovo argomento",
    defaultTitle: "RisolviCompiti",
    promptInfo: "La tua indicazione può essere breve, lunga o anche ambigua. Lascia i dettagli a noi; creeremo un titolo che racchiuda l'essenza del tuo concetto.",
    tones: {
      formal: "Formale",
      informative: "Informativo",
      objective: "Obiettivo",
      analytical: "Analitico",
      persuasive: "Persuasivo",
      narrative: "Narrativo",
      descriptive: "Descrittivo",
      informal: "Informale"
    },
    toneExamples: {
      formal: "Siamo lieti di presentare RisolviCompiti, una piattaforma di valutazione innovativa che utilizza la tecnologia di riconoscimento facciale per garantire l'integrità accademica negli ambienti di apprendimento a distanza.",
      informative: "RisolviCompiti è uno strumento di supervisione degli esami online. Utilizza il riconoscimento facciale per verificare l'identità degli studenti e monitorare il loro comportamento durante i test.",
      objective: "RisolviCompiti, una piattaforma di supervisione degli esami online, impiega la tecnologia di riconoscimento facciale per verificare l'identità degli studenti. Gli utenti segnalano una riduzione dei casi di frode accademica.",
      analytical: "Esaminando le capacità di RisolviCompiti, si osserva che il suo algoritmo di riconoscimento facciale non solo verifica l'identità, ma monitora anche il comportamento durante l'esame. Questo approccio duplice potrebbe portare a un aumento significativo dell'integrità accademica negli ambienti di apprendimento a distanza.",
      persuasive: "Ti preoccupa l'integrità degli esami online? Con RisolviCompiti, puoi stare tranquillo. La nostra tecnologia all'avanguardia garantisce che ogni studente sia chi dice di essere. Perché rischiare con soluzioni meno efficaci quando RisolviCompiti offre sicurezza totale?",
      narrative: "Era un giorno come un altro quando la professoressa Rossi scoprì RisolviCompiti. Non sapeva che questo strumento avrebbe completamente cambiato il modo in cui conduceva le sue valutazioni online, portandola a nuovi livelli di fiducia nell'integrità accademica dei suoi studenti.",
      descriptive: "RisolviCompiti si presenta come un guardiano digitale, con un'interfaccia intuitiva e robusta che vigila attentamente. I suoi algoritmi di riconoscimento facciale, come occhi instancabili, lavorano in silenzio per garantire che ogni studente sia autenticato e monitorato durante tutto l'esame.",
      informal: "Ciao! Stanco di preoccuparti per gli imbrogli negli esami online? RisolviCompiti al tuo servizio! È come avere un super professore virtuale che si assicura che tutti giochino pulito. Dì addio ai mal di testa della valutazione a distanza!"
    },
    lengthPrompt: "Scegli la lunghezza del tuo testo",
    lengthFormat: "Circa {0} - {1} caratteri / {2} - {3} parole",
    noThanks: "No, grazie",
    topicsInstructions: "Modifica o elimina gli argomenti che vuoi includere nel tuo saggio. Puoi anche aggiungere nuovi argomenti.",
    generatingEssay: "Generando saggio..."
  },
};

const EssayCreator = ({ setShowNoTokenModal }) => {
  const { currentLanguage } = useLanguage();

  const [token, clearToken] = useAuth();
  const [mobile, setMobile] = useState(window.innerWidth <= 768);
  const [step, setStep] = useState(1);
  const [topic, setTopic] = useState("");
  const [length, setLength] = useState(1800); // Default to 1800 words
  const { refreshSubscription } = useSubscription();
  const [tone, setTone] = useState("Objetivo");
  const [citationStyle, setCitationStyle] = useState("");
  const [generatedEssay, setGeneratedEssay] = useState("");
  const [suggestedTopics, setSuggestedTopics] = useState([
    "Introducción: El impacto de la inteligencia artificial en la sociedad moderna",
    "Historia y evolución de la IA: Desde sus inicios hasta la actualidad",
    "Aplicaciones actuales de la IA en diferentes sectores",
    "Ética y IA: Desafos y consideraciones morales",
    "El futuro de la IA: Tendencias y predicciones",
    "Conclusión: Reflexiones sobre el papel de la IA en nuestro futuro",
  ]);
  const [selectedTopics, setSelectedTopics] = useState([]);
  const [suggestedTitle, setSuggestedTitle] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isGeneratingEssay, setIsGeneratingEssay] = useState(false);
  const [lengthOption, setLengthOption] = useState(1); // 0, 1, 2, or 3 for the four options

  const lengthOptions = [
    { words: [600, 1200], chars: [3600, 7200] },
    { words: [1800, 3000], chars: [10800, 18000] },
    { words: [4200, 7200], chars: [25200, 43200] },
    { words: [9000, 15000], chars: [54000, 90000] },
  ];

  useEffect(() => {
    const handleResize = () => {
      setMobile(window.innerWidth <= 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const getSuggestedTitle = useCallback(
    async (text) => {
      if (text.length < 10) return; // Don't make API call for very short inputs
      try {
        const response = await fetch(`${apiUrl}/api/essay/suggest-title`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          },
          body: JSON.stringify({ topic: text }),
        });
        const data = await response.json();
        setSuggestedTitle(data.suggestedTitle);
      } catch (error) {
        console.error("Error fetching suggested title:", error);
      }
    },
    [token]
  );

  const debouncedGetSuggestedTitle = useCallback(
    debounce((text) => getSuggestedTitle(text), 500),
    [getSuggestedTitle]
  );

  useEffect(() => {
    if (topic) {
      debouncedGetSuggestedTitle(topic);
    } else {
      setSuggestedTitle("");
    }
  }, [topic, debouncedGetSuggestedTitle]);

  const handleTopicChange = (e) => {
    setTopic(e.target.value);
  };

  const handleLengthChange = (e) => {
    setLengthOption(parseInt(e.target.value));
  };

  const handleToneChange = (selectedTone) => {
    setTone(selectedTone);
  };

  const handleCitationStyleChange = (selectedStyle) => {
    setCitationStyle(selectedStyle);
    generateSuggestedTopics();
  };

  const generateSuggestedTopics = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(`${apiUrl}/api/essay/suggest-topics`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          topic: topic,
          length: length,
          tone: tone,
          citationStyle: citationStyle,
        }),
      });
      const data = await response.json();
      // Filtrar los temas vacíos
      const filteredTopics = data.suggestedTopics.filter(
        (topic) => topic.trim() !== ""
      );
      setSuggestedTopics(filteredTopics);
      setSelectedTopics(filteredTopics.map(() => true));
      setStep(5);
    } catch (error) {
      console.error("Error fetching suggested topics:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleTopicSelection = (index) => {
    setSelectedTopics((prevTopics) => {
      const newTopics = [...prevTopics];
      newTopics[index] = !newTopics[index];
      return newTopics;
    });
  };

  const handleTopicEdit = (index, newTopic) => {
    if (newTopic.trim() !== "") {
      setSuggestedTopics((prevTopics) => {
        const newTopics = [...prevTopics];
        newTopics[index] = newTopic;
        return newTopics;
      });
    }
  };

  const handleAddNewTopic = () => {
    const newTopic = texts[currentLanguage].newTopic;
    setSuggestedTopics((prevTopics) => [...prevTopics, newTopic]);
    setSelectedTopics((prevSelected) => [...prevSelected, true]);
  };

  const handleRemoveTopic = (index) => {
    setSuggestedTopics((prevTopics) =>
      prevTopics.filter((_, i) => i !== index)
    );
    setSelectedTopics((prevTopics) => prevTopics.filter((_, i) => i !== index));
  };

  const connectSocket = (onEssayResponse, onGenerationComplete) => {
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Socket connected.");
    });

    socket.on("disconnect", () => {
      console.log("Socket disconnected.");
    });

    socket.on("essay_response", (data) => {
      onEssayResponse(data);
    });

    socket.on("essay_end", (data) => {
      onGenerationComplete(data);
      socket.disconnect();
    });

    return socket;
  };

  const generateEssay = async () => {
    setIsGeneratingEssay(true);
    setStep(6); // Move to step 6 immediately
    setGeneratedEssay(""); // Clear any previous essay
    if (!token) {
      setShowNoTokenModal(true);
      console.error(texts[currentLanguage].noTokenError);
      setIsGeneratingEssay(false);
      return;
    }

    const socket = connectSocket(
      (data) => {
        if (data.essay_response) {
          setGeneratedEssay(data.essay_response);
        } 
      },
      (data) => {
        setGeneratedEssay(data.essay_end);
        setIsGeneratingEssay(false);
      }
    );

    try {
      await new Promise((resolve, reject) => {
        if (socket.connected) {
          refreshSubscription();
          resolve();
        } else {
          socket.on("connect", resolve);
          socket.on("connect_error", reject);
        }
      });

      const selectedLength = lengthOptions[lengthOption];
      const averageWords = Math.round(
        (selectedLength.words[0] + selectedLength.words[1]) / 2
      );

      const data = {
        topic: topic,
        length: averageWords, // Send the average number of words for the selected range
        tone: tone,
        citationStyle: citationStyle,
        selectedTopics: suggestedTopics.filter(
          (_, index) => selectedTopics[index]
        ),
      };

      socket.emit("generate_essay", {
        token: token,
        form: data,
      });
    } catch (error) {
      console.error("Error connecting to socket:", error);
      alert(texts[currentLanguage].noConnection);
      setIsGeneratingEssay(false);
    }
  };

  const goToStep = (newStep) => {
    if (newStep === 2 && !token) {
      setShowNoTokenModal(true);
      console.error(texts[currentLanguage].noTokenError);
      return;
    }
    if (newStep >= 1 && newStep <= 6) {
      setStep(newStep);
    }
  };

  const handleSuggestedTitleClick = () => {
    setTopic(suggestedTitle);
  };

  const renderStep = () => {
    if (isLoading) {
      return (
        <motion.div
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          className="flex flex-col items-center justify-center h-64"
        >
          <motion.div
            animate={{ rotate: 360 }}
            transition={{ duration: 1, repeat: Infinity, ease: "linear" }}
            className="w-16 h-16 border-t-4 border-blue-500 border-solid rounded-full"
          />
          <p className="mt-4 text-lg font-semibold text-gray-700">
            {texts[currentLanguage].loading}
          </p>
        </motion.div>
      );
    }

    switch (step) {
      case 1:
        return (
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-xl font-semibold mb-4 text-gray-800">
              {texts[currentLanguage].steps[0]}
            </h2>
            <p className="mb-4 text-gray-600">
              {texts[currentLanguage].promptInfo}
            </p>
            <textarea
              value={topic}
              onChange={handleTopicChange}
              className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
              rows="4"
              placeholder={texts[currentLanguage].placeholder}
            />
            {suggestedTitle && (
              <div
                className="mt-4 p-3 bg-blue-50 rounded-lg cursor-pointer hover:bg-blue-100 transition-colors duration-200"
                onClick={handleSuggestedTitleClick}
              >
                <p className="font-semibold text-blue-800">
                  {texts[currentLanguage].suggestedTopic}
                </p>
                <p className="text-blue-700">{suggestedTitle}</p>
              </div>
            )}
          </div>
        );
      case 2:
        return (
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              {texts[currentLanguage].steps[1]}
            </h2>
            <p className="mb-4 text-gray-600">
              {texts[currentLanguage].lengthPrompt}
            </p>
            <input
              type="range"
              min="0"
              max="3"
              step="1"
              value={lengthOption}
              onChange={handleLengthChange}
              className="w-full h-2 bg-blue-200 rounded-lg appearance-none cursor-pointer"
            />
            <p className="mt-2 text-gray-700">
              {texts[currentLanguage].lengthFormat
                .replace('{0}', lengthOptions[lengthOption].chars[0].toLocaleString())
                .replace('{1}', lengthOptions[lengthOption].chars[1].toLocaleString())
                .replace('{2}', lengthOptions[lengthOption].words[0].toLocaleString())
                .replace('{3}', lengthOptions[lengthOption].words[1].toLocaleString())}
            </p>
          </div>
        );
      case 3:
        return (
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              {texts[currentLanguage].steps[2]}
            </h2>
            <div className="flex flex-wrap gap-2 mb-4">
              {Object.entries(texts[currentLanguage].tones).map(([key, value]) => (
                <button
                  key={key}
                  onClick={() => handleToneChange(value)}
                  className={`px-4 py-2 rounded-full transition-colors duration-200 ${
                    tone === value ? "btn btn-primary" : "btn btn-ghost"
                  }`}
                >
                  {value}
                </button>
              ))}
            </div>
            <div className="mt-4 p-3 bg-blue-50 rounded-lg">
              <p className="font-semibold text-blue-800">
                {texts[currentLanguage].exampleTone} {tone}:
              </p>
              <p className="text-blue-700">
                {texts[currentLanguage].toneExamples[
                  Object.entries(texts[currentLanguage].tones).find(
                    ([_, value]) => value === tone
                  )?.[0] || 'formal'
                ]}
              </p>
            </div>
          </div>
        );
      case 4:
        return (
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              {texts[currentLanguage].steps[3]}
            </h2>
            <div className="flex flex-wrap gap-2 mb-4">
              {[
                texts[currentLanguage].noThanks,
                "MLA",
                "APA",
                "IEEE",
                "Chicago",
                "Harvard",
                "Vancouver",
                "UNE",
                "ISO 690",
              ].map((style) => (
                <button
                  key={style}
                  onClick={() => handleCitationStyleChange(style)}
                  className={`px-4 py-2 rounded-full transition-colors duration-200 ${
                    citationStyle === style
                      ? "btn btn-primary"
                      : "btn btn-ghost"
                  }`}
                >
                  {style}
                </button>
              ))}
            </div>
            {citationStyle && citationStyle !== texts[currentLanguage].noThanks && (
              <div className="mt-4 p-3 bg-blue-50 rounded-lg">
                <p className="font-semibold text-blue-800">
                  {texts[currentLanguage].citationStyleInfo} {citationStyle}:
                </p>
                <p className="text-blue-700">
                  {citationStyle === "MLA" &&
                    "El estilo MLA (Modern Language Association) se usa comúnmente en humanidades, especialmente en lengua y literatura. Utiliza citas entre paréntesis en el texto y una lista de obras citadas al final."}
                  {citationStyle === "APA" &&
                    "El estilo APA (American Psychological Association) se utiliza ampliamente en ciencias sociales y educación. Emplea el sistema autor-fecha para las citas en el texto y una lista de referencias al final."}
                  {citationStyle === "IEEE" &&
                    "El estilo IEEE (Institute of Electrical and Electronics Engineers) se usa principalmente en ingeniería y ciencias de la computación. Utiliza números entre corchetes para las citas en el texto y una lista numerada de referencias al final."}
                  {citationStyle === "Chicago" &&
                    "El estilo Chicago ofrece dos sistemas: notas y bibliografía (usado en humanidades) y autor-fecha (usado en ciencias sociales y naturales). Ambos incluyen una bibliografía completa al final."}
                  {citationStyle === "Harvard" &&
                    "El estilo Harvard, similar al APA, utiliza el sistema autor-fecha para las citas en el texto y una lista de referencias al final. Es común en universidades británicas y australianas."}
                  {citationStyle === "Vancouver" &&
                    "El estilo Vancouver se usa principalmente en ciencias biomédicas. Utiliza números entre paréntesis o superíndices para las citas en el texto y una lista numerada de referencias al final."}
                  {citationStyle === "UNE" &&
                    "El estilo UNE (Una Norma Española) es un sistema de citación desarrollado por AENOR, muy utilizado en España. Proporciona pautas para citar diversos tipos de documentos en trabajos académicos y científicos."}
                  {citationStyle === "ISO 690" &&
                    "La norma ISO 690 es un estándar internacional para referencias bibliográficas y citas. Es flexible y se puede adaptar a diferentes disciplinas, siendo común en trabajos académicos en España y otros países europeos."}
                </p>
              </div>
            )}
          </div>
        );
      case 5:
        return (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            className="bg-white p-6 rounded-lg"
          >
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              {texts[currentLanguage].steps[4]}
            </h2>
            <p className="mb-4 text-gray-600">
              {texts[currentLanguage].topicsInstructions}
            </p>
            {suggestedTopics.map(
              (topic, index) =>
                topic.trim() !== "" && (
                  <div key={index} className="flex items-center mb-2">
                    <input
                      type="text"
                      value={topic}
                      onChange={(e) => handleTopicEdit(index, e.target.value)}
                      className="flex-grow p-2 border border-gray-300 rounded"
                    />
                    <button
                      onClick={() => handleRemoveTopic(index)}
                      className="btn btn-outline ml-4"
                    >
                      {texts[currentLanguage].remove}
                    </button>
                  </div>
                )
            )}
            <div className="mt-4 flex space-x-4">
              <button onClick={handleAddNewTopic} className="btn btn-outline">
                {texts[currentLanguage].addNewTopic}
              </button>
            </div>
          </motion.div>
        );
      case 6:
        return (
          <div className="bg-white p-6 rounded-lg">
            <h2 className="text-2xl font-bold mb-4 text-gray-800">
              {texts[currentLanguage].steps[5]}
            </h2>
            <div className="border border-gray-300 p-4 rounded-lg bg-gray-50 whitespace-pre-wrap">
            <MarkdownPreview content={generatedEssay || texts[currentLanguage].generatingEssay} />
            </div>
            {!isGeneratingEssay && (
              <button onClick={() => setStep(1)} className="btn btn-primary mt-4">
                {texts[currentLanguage].createAnotherEssay}
              </button>
            )}
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <Helmet>
        <title>{texts[currentLanguage].title}</title>
        <meta name="description" content={texts[currentLanguage].metaDescription} />
      </Helmet>
      <CurrentPath text={texts[currentLanguage].path} />
      <div className="max-w-4xl mx-auto mt-8">
        <h1 className="text-3xl font-bold mb-6 text-center text-gray-800">
          {texts[currentLanguage].titleApp}
        </h1>

        <div className="mb-6">
          <div className="flex justify-between items-center">
            {[1, 2, 3, 4, 5, 6].map((s) => (
              <React.Fragment key={s}>
                <div
                  className={`w-8 h-8 rounded-full ${
                    s <= step ? "bg-primary" : "bg-gray-300"
                  } flex items-center justify-center text-white text-sm font-medium cursor-pointer`}
                  onClick={() => goToStep(s)}
                >
                  {s}
                </div>
                {s < 6 && (
                  <div
                    className={`flex-grow h-0.5 mx-2 ${
                      s < step ? "bg-primary" : "bg-gray-300"
                    }`}
                  />
                )}
              </React.Fragment>
            ))}
          </div>
        </div>

        <div className="bg-white rounded-lg shadow-md p-6 mb-6">
          {renderStep()}
        </div>

        <div className="flex justify-between mt-6">
          {step > 1 && !isLoading && (
            <button
              onClick={() => goToStep(step - 1)}
              className="btn btn-outline"
            >
              {texts[currentLanguage].previous}
            </button>
          )}
          {step < 5 && !isLoading && (
            <button
              onClick={() => goToStep(step + 1)}
              className={`btn btn-primary ${
                step === 1 && !topic.trim() ? "btn-ghost" : ""
              }`}
              disabled={step === 1 && !topic.trim()}
            >
              {texts[currentLanguage].next}
            </button>
          )}
          {step === 5 && !isLoading && (
            <button onClick={generateEssay} className="btn btn-primary">
              {texts[currentLanguage].generateEssay}
            </button>
          )}
        </div>
      </div>
      {!token && !mobile && <SEOEssayCreator />}
    </div>
  );
};

export default EssayCreator;
