import React from "react";
import { Helmet } from "react-helmet";
import FAQ from "./FAQ";
import Precios from "./Precios";
import { useLanguage } from "./useLanguaje";

export default function VerPrecios() {
  const { currentLanguage } = useLanguage();
  const texts = {
    es: {
      userOpinions: "Lo que opinan nuestros usuarios",
      pageTitle: "Ver Precios | FotoExamen",
      pageDescription: "Consulta los precios de nuestros servicios en FotoExamen.",
    },
    en: {
      userOpinions: "What our users think",
      pageTitle: "View Prices | PhotoExamAI",
      pageDescription: "Check out the pricing for our services at PhotoExamAI.",
    },
    it: {
      userOpinions: "Cosa pensano i nostri utenti",
      pageTitle: "Visualizza Prezzi | RisolviCompiti",
      pageDescription: "Scopri i prezzi dei nostri servizi su RisolviCompiti.",
    },
  };

  return (
    <>
      <Helmet>
        <title>{texts[currentLanguage].pageTitle}</title>
        <meta name="description" content={texts[currentLanguage].pageDescription} />
      </Helmet>
      <Precios />
      <FAQ />
      <div className="container mx-auto px-4 py-8">
        <h2 className="text-2xl font-bold mb-6">
          {texts[currentLanguage].userOpinions}
        </h2>
        <div>
          <div
            className="senja-embed"
            data-id="960749eb-7604-4965-b221-d325ecf87cbb"
            data-mode="shadow"
            data-lazyload="false"
            style={{ display: "block" }}
          ></div>
          <script
            src="https://widget.senja.io/widget/960749eb-7604-4965-b221-d325ecf87cbb/platform.js"
            type="text/javascript"
            async
          ></script>
        </div>
      </div>
    </>
  );
}
