import React, { useEffect, useRef, useState } from "react";
import { Helmet } from "react-helmet";
import GoogleAuth from "./GoogleAuth";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";

const APIURL = `${process.env.REACT_APP_API_URL}/api/easyexams`;
const TURNSTILE_SITE_KEY = "0x4AAAAAAAkKxkBp6yJYqY5I"; // Tu clave de sitio

export default function Login() {
  const [token, clearToken] = useAuth();
  const turnstileRef = useRef(null);
  const [turnstileToken, setTurnstileToken] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      completeCaptcha: "Por favor, complete el captcha.",
      validEmail: "Por favor, proporciona un correo electrónico válido.",
      acceptDisclaimer:
        "Por favor, acepta el descargo de responsabilidad para continuar.",
      emailValidationError: "Hubo un error al validar el email.",
      loginLinkSent:
        "Hemos enviado un enlace de inicio de sesión a tu buzón. Por favor, revisa tu correo electrónico y la carpeta de SPAM si no lo ves.",
      postRequestError:
        "Hubo un error al realizar la solicitud. Inténtalo de nuevo recargando la web.",
      loginTitle: "Inicio de Sesión / Registro",
      registerPrompt:
        "Regístrate con tu correo o cuenta de Google. Si ya tienes cuenta, inicia sesión.",
      emailPlaceholder: "Correo electrónico",
      termsOfService: "términos de uso del servicio",
      submitButton: { submitting: "Enviado", default: "Entrar" },
      orText: "o",
      checkbox: "Acepto recibir correos electrónicos y los",
      pageTitle: "Inicio de Sesión | FotoExamen",
      pageDescription: "Accede o regístrate en FotoExamen para disfrutar de nuestros servicios educativos personalizados."
    },
    en: {
      completeCaptcha: "Please complete the captcha.",
      validEmail: "Please provide a valid email address.",
      acceptDisclaimer: "Please accept the disclaimer to continue.",
      emailValidationError: "There was an error validating the email.",
      loginLinkSent:
        "We have sent a login link to your inbox. Please check your email and the SPAM folder if you don't see it.",
      postRequestError:
        "There was an error making the request. Please try again by reloading the web page.",
      loginTitle: "Login / Register",
      registerPrompt:
        "Register with your email or Google account. If you already have an account, log in.",
      emailPlaceholder: "Email",
      termsOfService: "terms of service",
      submitButton: { submitting: "Submitting", default: "Enter" },
      orText: "or",
      checkbox: "I agree to receive emails and the",
      pageTitle: "Login | PhotoExamAI",
      pageDescription: "Log in or register at PhotoExamAI to enjoy our personalized educational services."
    },
    it: {
      completeCaptcha: "Per favore, completa il captcha.",
      validEmail: "Per favore, fornisci un indirizzo email valido.",
      acceptDisclaimer: "Per favore, accetta il disclaimer per continuare.",
      emailValidationError: "Si è verificato un errore durante la convalida dell'email.",
      loginLinkSent: "Abbiamo inviato un link di accesso alla tua casella di posta. Controlla la tua email e la cartella SPAM se non lo vedi.",
      postRequestError: "Si è verificato un errore durante la richiesta. Riprova ricaricando la pagina web.",
      loginTitle: "Accesso / Registrazione",
      registerPrompt: "Registrati con la tua email o il tuo account Google. Se hai già un account, accedi.",
      emailPlaceholder: "Email",
      termsOfService: "termini del servizio",
      submitButton: { submitting: "Invio", default: "Accedi" },
      orText: "o",
      checkbox: "Accetto di ricevere email e i",
      pageTitle: "Accesso | RisolviCompiti",
      pageDescription: "Accedi o registrati su RisolviCompiti per usufruire dei nostri servizi educativi personalizzati."
    }
  };

  useEffect(() => {
    if (token) {
      window.location.href = "/";
    }

    const urlParams = new URLSearchParams(window.location.search);
    const clientReferenceId = urlParams.get("client_reference_id");
    if (clientReferenceId) {
      localStorage.setItem("client_reference_id", clientReferenceId);
    }

    const script = document.createElement("script");
    script.src =
      "https://challenges.cloudflare.com/turnstile/v0/api.js?render=explicit";
    script.async = true;
    script.defer = true;
    document.body.appendChild(script);

    script.onload = () => {
      if (turnstileRef.current && !turnstileRef.current.hasChildNodes()) {
        window.turnstile.render(turnstileRef.current, {
          sitekey: TURNSTILE_SITE_KEY,
          callback: function (token) {
            console.log("Turnstile token:", token);
            setTurnstileToken(token);
            // Hide the captcha once it's successfully completed
            turnstileRef.current.style.display = "none";
          },
        });
      }
    };

    return () => {
      document.body.removeChild(script);
    };
  }, [token]);

  async function signIn(e) {
    e.preventDefault();
    if (!turnstileToken) {
      alert(texts[currentLanguage].completeCaptcha);
      return;
    }
    if (isSubmitting) return;
    setIsSubmitting(true);
    await sendDataToAPI(turnstileToken);
  }

  async function sendDataToAPI(turnstileToken) {
    const emailInput = document.getElementById("email");
    const disclaimerCheckbox = document.getElementById("disclaimer");
    const clientReferenceId = localStorage.getItem("client_reference_id");

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(emailInput.value)) {
      alert(texts[currentLanguage].validEmail);
      setIsSubmitting(false);
      return;
    }

    if (!disclaimerCheckbox.checked) {
      alert(texts[currentLanguage].acceptDisclaimer);
      setIsSubmitting(false);
      return;
    }

    const bodyParams = {
      email: emailInput.value,
      "cf-turnstile-response": turnstileToken,
      'lang': currentLanguage
    };

    if (clientReferenceId) {
      bodyParams["client_reference_id"] = clientReferenceId;
    }

    try {
      const response = await fetch(APIURL + "/emailvalidate", {
        method: "POST",
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams(bodyParams),
      });

      if (response.status !== 200) {
        const text = await response.text();
        alert(text || texts[currentLanguage].emailValidationError);
        setIsSubmitting(false);
        return;
      }

      const responseData = await response.json();
      if (responseData?.token) {
        window.location.href = `/?token=${responseData.token}`;
        return;
      }

      alert(texts[currentLanguage].loginLinkSent);
    } catch (error) {
      console.error("Error al realizar la solicitud POST:", error);
      alert(texts[currentLanguage].postRequestError);
      setIsSubmitting(false);
    }
  }

  return (
    <div className="max-w-xl mx-auto my-12">
      <Helmet>
        <title>{texts[currentLanguage].pageTitle}</title>
        <meta name="description" content={texts[currentLanguage].pageDescription} />
      </Helmet>
      <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        <div className="flex items-center justify-between">
          <h1
            className="text-2xl leading-6 font-medium text-gray-900 mb-2"
            id="modal-title"
          >
            {texts[currentLanguage].loginTitle}
          </h1>
        </div>
        <p className="mt-4 text-sm text-gray-500">{texts[currentLanguage].registerPrompt}</p>
        <form onSubmit={signIn}>
          <div className="flex items-center justify-center mb-2 mt-2">
            <label htmlFor="email" className="mb-2 hidden">
              {texts[currentLanguage].emailPlaceholder}:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              required
              className="input input-bordered w-full"
              placeholder={texts[currentLanguage].emailPlaceholder}
            />
          </div>
          <div className="flex items-center text-xs mb-4">
            <input
              type="checkbox"
              id="disclaimer"
              name="disclaimer"
              required
              className="mr-2"
            />
            <label htmlFor="disclaimer">
              {texts[currentLanguage].checkbox} {" "}
              <a
                href={currentLanguage === "es" ? "https://www.fotoexamen.com/condiciones-de-uso.html" : "https://www.photoexamai.com/terms-of-use.html"}
                target="_blank"
                rel="noopener noreferrer"
                className="text-blue-500 hover:text-blue-700 underline"
              >
                {texts[currentLanguage].termsOfService}
              </a>
            </label>
          </div>
          <div ref={turnstileRef} className="my-4"></div>
          <button
            type="submit"
            className="btn btn-primary w-full"
            disabled={!turnstileToken || isSubmitting}
          >
            {isSubmitting
              ? texts[currentLanguage].submitButton.submitting
              : texts[currentLanguage].submitButton.default}
          </button>
          <div className="flex items-center my-4">
            <hr className="flex-grow" />
            <span className="mx-2 text-gray-500">{texts[currentLanguage].orText}</span>
            <hr className="flex-grow" />
          </div>
          <GoogleAuth />
        </form>
      </div>
    </div>
  );
}
