import * as pdfjsLib from "pdfjs-dist/webpack";
import { default as React, useState } from "react";
import { Helmet } from "react-helmet";
import CurrentPath from "./CurrentPath";
import MarkdownPreview from "./MarkdownPreview";
import SEOSumarize from "./seo/SEOSumarize";
import { useSubscription } from "./SubscriptionContext";
import useAuth from "./useAuthToken";
import { useLanguage } from "./useLanguaje";
import useMobile from "./useMobile";

const apiUrl = process.env.REACT_APP_API_URL;
const Summary = ({ setShowNoTokenModal }) => {
  const [file, setFile] = useState(null);
  const [text, setText] = useState("");
  const [summary, setSummary] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();
  const mobile = useMobile();
  const { currentLanguage } = useLanguage();

  const texts = {
    es: {
      uploadPrompt: "Por favor, sube un archivo o escribe algún texto antes de generar un resumen.",
      noAuthToken: "No authentication token found.",
      pdfError: "Error al extraer el texto del PDF. Por favor, prueba con otro archivo.",
      unsupportedFile: "Tipo de archivo no admitido. Sube una imagen o un archivo PDF.",
      tooLong: "El texto es demasiado extenso. Por favor, sube otro archivo más pequeño o introduce menos texto. Número de tokens excedido has subido: ",
      errorFetching: "Error pruebe nuevamente o con otro archivo más pequeño. Si el problema persiste contacte con nosotros.",
      processing: "Procesando...",
      generateSummary: "Generar Resumen",
      textPlaceholder: "Escribe aquí tu texto...",
      title: "📄 Generador de Resúmenes",
      description: "Sube un documento en PDF o varias imágenes o puedes introducir texto directamente para obtener un resumen conciso y eficiente.",
      subtitle: "Escribe un texto o sube un PDF",
      pageTitle: "Generador de Resúmenes | FotoExamen - Resúmenes Eficientes",
      pageDescription: "Genera resúmenes concisos y eficientes a partir de tus documentos o texto con FotoExamen.",
    },
    en: {
      uploadPrompt: "Please upload a file or write some text before generating a summary.",
      noAuthToken: "No authentication token found.",
      pdfError: "Error extracting text from PDF. Please try another file.",
      unsupportedFile: "Unsupported file type. Upload an image or a PDF file.",
      tooLong: "The text is too long. Please upload a smaller file or enter less text. Tokens exceeded: ",
      errorFetching: "Error, please try again or with a smaller file. If the problem persists, contact us.",
      processing: "Processing...",
      generateSummary: "Generate Summary",
      textPlaceholder: "Write your text here...",
      title: "📄 Summary Generator",
      description: "Upload a PDF document or multiple images, or you can enter text directly to get a concise and efficient summary.",
      subtitle: "Write text or upload a PDF",
      pageTitle: "Summary Generator | PhotoExamAI - Efficient Summaries",
      pageDescription: "Generate concise and efficient summaries from your documents or text with PhotoExamAI.",
    },
    it: {
      uploadPrompt: "Carica un file o scrivi del testo prima di generare un riassunto.",
      noAuthToken: "Nessun token di autenticazione trovato.",
      pdfError: "Errore durante l'estrazione del testo dal PDF. Prova con un altro file.",
      unsupportedFile: "Tipo di file non supportato. Carica un'immagine o un file PDF.",
      tooLong: "Il testo è troppo lungo. Carica un file più piccolo o inserisci meno testo. Token superati: ",
      errorFetching: "Errore, riprova o con un file più piccolo. Se il problema persiste, contattaci.",
      processing: "Elaborazione...",
      generateSummary: "Genera Riepilogo",
      textPlaceholder: "Scrivi qui il tuo testo...",
      title: "📄 Generatore di Riepiloghi",
      description: "Carica un documento PDF o più immagini, oppure puoi inserire direttamente del testo per ottenere un riassunto conciso ed efficiente.",
      subtitle: "Scrivi del testo o carica un PDF",
      pageTitle: "Generatore di Riepiloghi | RisolviCompiti - Riepiloghi Efficienti",
      pageDescription: "Genera riepiloghi concisi ed efficienti dai tuoi documenti o testo con RisolviCompiti.",
    }
  };

  const handleFileChange = (event) => {
    setFile(event.target.files[0]);
    setText("");
    setSummary("");
  };

  const handleTextChange = (event) => {
    setText(event.target.value);
    setFile(null);
    setSummary("");
  };

  const handleSummarize = async () => {
    if (subscriptionType === "No Subscription") {
      setShowNoTokenModal(true);
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0) {
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    if (!file && !text.trim()) {
      alert(texts[currentLanguage].uploadPrompt);
      return;
    }

    if (!token) {
      setShowNoTokenModal(true);
      console.error(texts[currentLanguage].noAuthToken);
      return;
    }

    setIsLoading(true);
    setErrorMessage("");

    const formData = new FormData();
    let pdftext = "";

    if (file) {
      if (file.type === "none") {
        try {
          const pdf = await pdfjsLib.getDocument(URL.createObjectURL(file)).promise;
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const text = await page.getTextContent();
            const pageText = text.items.map(item => item.str).join(" ");
            pdftext += pageText + " ";
          }
          pdftext = pdftext.trim();
          formData.append("text", pdftext);
        } catch (error) {
          console.error("Error extracting text from PDF:", error);
          setErrorMessage(texts[currentLanguage].pdfError);
          setIsLoading(false);
          return;
        }
      } else if (file.type.startsWith("image/") || file.type === "application/pdf") {
        formData.append("files", file);
      } else {
        alert(texts[currentLanguage].unsupportedFile);
        setIsLoading(false);
        return;
      }
    } else {
      formData.append("text", text);
    }

    try {
      const response = await fetch(`${apiUrl}/api/easyexams/summary`, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${token}`,
        },
        body: formData,
      });
      const data = await response.json();

      if (data?.too_long) {
        setErrorMessage(
          texts[currentLanguage].tooLong +
          data?.total_tokens +
          " y el máximo son " +
          data?.max_tokens +
          " tokens."
        );
        return;
      }

      if (!response.ok) {
        throw new Error(data.message || "Error fetching the summary.");
      }

      setSummary(data.summary);
      refreshSubscription();
    } catch (error) {
      console.error("Error summarizing text:", error);
      setErrorMessage(texts[currentLanguage].errorFetching);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <Helmet>
        <title>{texts[currentLanguage].pageTitle}</title>
        <meta name="description" content={texts[currentLanguage].pageDescription} />
      </Helmet>
      <CurrentPath text={texts[currentLanguage].title} />
      <div className="container max-w-4xl mx-auto p-0 mt-8 sm:p-8 rounded">
        <h1 className="text-4xl font-bold border-b-4 border-white pb-2 text-center">
          {texts[currentLanguage].title}
        </h1>
        <p className="mb-6">
          {texts[currentLanguage].description}
        </p>
        <h2 className="text-2xl font-bold mb-6 border-b-4 border-white pb-2 text-center">
          {texts[currentLanguage].subtitle}
        </h2>
        <div>
          {!file && (
            <textarea
              className="textarea textarea-bordered h-24 w-full"
              placeholder={texts[currentLanguage].textPlaceholder}
              value={text}
              onChange={handleTextChange}
            ></textarea>
          )}

          {!text && (
            <input
              type="file"
              onChange={handleFileChange}
              className="file-input file-input-bordered w-full"
            />
          )}
        </div>

        <button
          onClick={handleSummarize}
          className="btn btn-primary mt-4 mb-8 w-full sm:w-auto"
        >
          {texts[currentLanguage].generateSummary}
        </button>

        {isLoading && (
          <div className="flex flex-col justify-center items-center">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-green-600 my-4"></div>
            <p className="text-lg font-semibold">{texts[currentLanguage].processing}</p>
          </div>
        )}

        {errorMessage && (
          <div className="shadow-lg mt-4 p-4 rounded">
            <div>
              <span>{errorMessage}</span>
            </div>
          </div>
        )}

        {summary && (
          <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line mt-8">
            <MarkdownPreview content={summary} />
          </div>
        )}
      </div>
      {!token && !mobile && <SEOSumarize />}
    </div>
  );
};

export default Summary;
