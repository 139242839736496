import React, { useRef } from "react";
import { FaFilePdf } from "react-icons/fa";
import { BlockMath, InlineMath } from "react-katex";
import ReactMarkdown from "react-markdown";
import rehypeKatex from "rehype-katex";
import rehypeSanitize from "rehype-sanitize";
import remarkMath from "remark-math";
import { useLanguage } from "./useLanguaje"; // Import the useLanguage hook

const texts = {
  en: {
    downloadPDFButton: "Download Solution",
  },
  es: {
    downloadPDFButton: "Descargar Solución",
  },
  it: {
    downloadPDFButton: "Scarica Soluzione",
  },
};

const MarkdownPreview = ({ content }) => {
  const printRef = useRef();
  const { currentLanguage } = useLanguage(); // Use the hook to get current language

  const handlePrint = () => {
    const printContent = printRef.current;
    const originalContent = document.body.innerHTML;

    const printDiv = document.createElement("div");
    const katexStyles = document.createElement("link");
    katexStyles.rel = "stylesheet";
    katexStyles.href =
      "https://cdn.jsdelivr.net/npm/katex@0.16.9/dist/katex.min.css";

    printDiv.appendChild(katexStyles);
    printDiv.appendChild(printContent.cloneNode(true));

    document.body.innerHTML = "";
    document.body.appendChild(printDiv);

    katexStyles.onload = () => {
      window.print();
      document.body.innerHTML = originalContent;
    };
  };

  return (
    <div>
      <div className="text-center mt-4">
        <button onClick={handlePrint} className="btn btn-primary mb-4">
          <FaFilePdf size={24} className="mr-2" /> {texts[currentLanguage].downloadPDFButton}
        </button>
      </div>
      <div ref={printRef} className="whitespace-pre-line">
        <ReactMarkdown
          rehypePlugins={[rehypeSanitize, rehypeKatex]}
          remarkPlugins={[remarkMath]}
          components={{
            math: ({ value }) => <BlockMath math={value} />,
            inlineMath: ({ value }) => <InlineMath math={value} />,
          }}
          disallowedElements={["pre"]}
          unwrapDisallowed
        >
          {content}
        </ReactMarkdown>
      </div>
    </div>
  );
};

export default MarkdownPreview;
