import { faCamera } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import 'katex/dist/katex.min.css';
import * as pdfjsLib from "pdfjs-dist/webpack";
import React, { useEffect, useRef, useState } from "react";
import { FaFilePdf } from "react-icons/fa";
import { useLocation, useNavigate } from "react-router-dom";
import { io } from "socket.io-client";
import CurrentPath from "./CurrentPath.js";
import MarkdownPreview from "./MarkdownPreview.js";
import SEOExamSolver from "./seo/SEOExamSolver.js";
import { useSubscription } from "./SubscriptionContext.js";
import useAuth from "./useAuthToken.js";
import { useLanguage } from "./useLanguaje.jsx";
import useMobile from "./useMobile.js";
const apiUrl = process.env.REACT_APP_API_URL;

const ExamSolver = ({ setShowNoTokenModal }) => {
  const { currentLanguage } = useLanguage();
  const [image, setImage] = useState(null);
  const [imagebin, setImagebin] = useState(null);
  const [ocrText, setOcrText] = useState("");
  const [examResult, setExamResult] = useState("");
  const [isStripeModalVisible, setIsStripeModalVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [examData, setExamData] = useState(null);
  const [isLoadingOcr, setIsLoadingOcr] = useState(false);
  const [showTipsModal, setShowTipsModal] = useState(false);
  const [pdfUrl, setPdfUrl] = useState(null);
  const [pdfPreview, setPdfPreview] = useState(null);
  const { subscriptionType, loading, error, refreshSubscription, remainingCredits } = useSubscription();
  const [token, clearToken] = useAuth();
  const [isDownloading, setIsDownloading] = useState(false);
  const mobile = useMobile();

  const location = useLocation();
  const navigate = useNavigate();
  const stripeModalRef = useRef(null);
  const socketRef = useRef(null);
  const messageCount = useRef(0);

  const [containsMathOrGraphics, setContainsMathOrGraphics] = useState(false);
  const [responseType, setResponseType] = useState("detailed");
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

  const [isPlaying, setIsPlaying] = useState(false);
  const [audioChunks, setAudioChunks] = useState([]);
  const audioContextRef = useRef(null);
  const audioSourceRef = useRef(null);
  const [processedAudio, setProcessedAudio] = useState(null);

  const [showExtensionCard, setShowExtensionCard] = useState(() => {
    return localStorage.getItem('hideExtensionCard') !== 'true';
  });

const textContent = {
  es: {
    currentPath: "Resuelve tu examen",
    title: "✍🏻 Resuelve tu examen",
    description: "Utiliza nuestra inteligencia artificial personalizada para resolver todos tus exámenes, sube una imagen o un PDF de tu examen o tarea. Edita el texto detectado si encuentras algún error y presiona resolver examen cuando estes listo. Puedes ver el resultado o descargarlo en formato PDF.",
    uploadExam: "Sube tu examen o escribe tu texto:",
    takePhoto: "Tomar Foto",
    detectedText: "Texto detectado:",
    detectingText: "Detectando el texto del archivo o imagen...",
    resolvingExam: "Resolviendo examen con nuestra IA avanzada...",
    containsMathOrGraphics: "El examen contiene fórmulas matemáticas o gráficos",
    resolveExam: "Resolver Examen",
    usageTips: "Recomendaciones de uso",
    examResult: "Resultado del examen:",
    downloadingPDF: "Descargando PDF...",
    downloadPDF: "Descargar PDF",
    freeCreditsUsed: "Gastaste tus créditos gratis",
    continueUsing: "¿Quieres seguir usando FotoExamen? Descubre los beneficios increíbles de nuestros planes premium:",
    unlimitedAccess: "🚀 Acceso ilimitado a resoluciones de exámenes.",
    prioritySupport: "💡 Soporte prioritario para ayudarte cuando más lo necesitas.",
    priorityProcessing: "🔐 Procesamiento prioritario de tus solicitudes.",
    advancedTools: "🎓 Herramientas avanzadas para un aprendizaje más eficaz.",
    additionalResources: "💼 Recursos adicionales para prepararte para tus exámenes.",
    investInSuccess: "¡Invierte en tu éxito académico, elige un plan premium hoy y siente la diferencia!",
    viewPlans: "Ver planes 💲",
    tipsTitle: "Tips para usar la plataforma",
    clearPhoto: "Fotografía clara: Asegúrate de tomar fotos claras donde el texto esté completamente horizontal. Esto mejora la precisión con la que la plataforma puede interpretar y procesar la información. Además la imagen no debe superar los 20Mb y los formatos aceptados son: 'png', 'jpeg', 'gif', 'webp'",
    repeatProcess: "Repetir el proceso: No dudes en resolver varias veces la misma foto. A veces, la plataforma puede ofrecer resultados ligeramente diferentes, por lo que es útil hacer varias pruebas para obtener la mejor respuesta.",
    mathReasoning: "Razonamiento en matemáticas: En exámenes de matemáticas, es crucial no solo fiarse de las respuestas automticas. Toma los razonamientos que te ofrece la plataforma y luego verifica los cálculos manualmente para asegurarte de que son correctos.",
    useTutors: "Usa los tutores: Si tienes dudas sobre alguna respuesta, aprovecha la sección de tutores de la plataforma. Puedes subir el ejercicio en cuestión y hacer todas las preguntas que necesites para aclarar tus dudas y entender mejor el material.",
    close: "Cerrar",
    chromeExtension: "¡Nuevo! 🚀 Descarga nuestra extensión de Chrome",
    chromeExtensionDesc: "Resuelve tus exámenes directamente desde cualquier página web",
    downloadExtension: "Descargar Extensión",
    pdfTooLarge: "El PDF es demasiado grande. Por favor, sube un PDF con 10 páginas o menos.",
    responseTypeLabel: "Tipo de respuesta:",
    responseTypeDetailed: "Con explicación detallada",
    responseTypeBrief: "Solo resultado",
    advancedOptions: "Opciones avanzadas",
    mathFormulasLabel: "Fórmulas matemáticas o gráficos",
    readAnswer: "Leer respuesta",
    readingAnswer: "Leyendo...",
    premiumFeature: "Esta función es solo para usuarios premium",
  },
  it: {
    currentPath: "Risolvi il tuo compito",
    title: "✍🏻 Risolvi il tuo compito",
    description: "Utilizza la nostra intelligenza artificiale personalizzata per risolvere tutti i tuoi compiti, carica un'immagine o un PDF del tuo compito. Modifica il testo rilevato se trovi errori e premi risolvi compito quando sei pronto. Puoi visualizzare il risultato o scaricarlo in formato PDF.",
    uploadExam: "Carica il tuo compito o scrivi il tuo testo:",
    takePhoto: "Scatta una foto",
    detectedText: "Testo rilevato:",
    detectingText: "Rilevamento del testo dal file o dall'immagine...",
    resolvingExam: "Risoluzione compito con la nostra IA avanzata...",
    containsMathOrGraphics: "Il compito contiene formule matematiche o grafici",
    resolveExam: "Risolvi Compito",
    usageTips: "Consigli d'uso",
    examResult: "Risultato del compito:",
    downloadingPDF: "Download PDF...",
    downloadPDF: "Scarica PDF",
    freeCreditsUsed: "Hai usato i tuoi crediti gratuiti",
    continueUsing: "Vuoi continuare a utilizzare RisolviCompiti? Scopri gli incredibili vantaggi dei nostri piani premium:",
    unlimitedAccess: "🚀 Accesso illimitato alle risoluzioni dei compiti.",
    prioritySupport: "💡 Supporto prioritario per aiutarti quando più ne hai bisogno.",
    priorityProcessing: "🔐 Elaborazione prioritaria delle tue richieste.",
    advancedTools: "🎓 Strumenti avanzati per un apprendimento più efficace.",
    additionalResources: "💼 Risorse aggiuntive per prepararti ai tuoi compiti.",
    investInSuccess: "Investi nel tuo successo accademico, scegli un piano premium oggi e senti la differenza!",
    viewPlans: "Vedi piani 💲",
    tipsTitle: "Consigli per utilizzare la piattaforma",
    clearPhoto: "Foto chiara: Assicurati di scattare foto chiare dove il testo sia completamente orizzontale. Questo migliora la precisione con cui la piattaforma può interpretare ed elaborare le informazioni. Inoltre l'immagine non deve superare i 20Mb e i formati accettati sono: 'png', 'jpeg', 'gif', 'webp'",
    repeatProcess: "Ripeti il processo: Non esitare a risolvere più volte la stessa foto. A volte, la piattaforma può offrire risultati leggermente diversi, quindi è utile fare diverse prove per ottenere la migliore risposta.",
    mathReasoning: "Ragionamento matematico: Negli esami di matematica, è fondamentale non fidarsi solo delle risposte automatiche. Prendi i ragionamenti che ti offre la piattaforma e poi verifica i calcoli manualmente per assicurarti che siano corretti.",
    useTutors: "Utilizza i tutor: Se hai dubbi su una risposta, approfitta della sezione tutor della piattaforma. Puoi caricare l'esercizio in questione e fare tutte le domande che ti servono per chiarire i tuoi dubbi e capire meglio il materiale.",
    close: "Chiudi",
    chromeExtension: "Nuovo! 🚀 Scarica la nostra estensione per Chrome",
    chromeExtensionDesc: "Risolvi i tuoi esami direttamente da qualsiasi pagina web",
    downloadExtension: "Scarica l'estensione",
    pdfTooLarge: "Il PDF è troppo grande. Carica un PDF con 10 pagine o meno.",
    responseTypeLabel: "Tipo di risposta:",
    responseTypeDetailed: "Con spiegazione dettagliata",
    responseTypeBrief: "Solo risultato",
    advancedOptions: "Opzioni avanzate",
    mathFormulasLabel: "Formule matematiche o grafici",
    readAnswer: "Leggi risposta",
    readingAnswer: "Leggendo...",
    premiumFeature: "Questa funzione è solo per utenti premium",
  },
  en: {
    currentPath: "Solve your exam",
    title: "✍🏻 Solve your exam",
    description: "Use our personalized artificial intelligence to solve all your exams, upload an image or a PDF of your exam or task. Edit the detected text if you find any errors and press solve exam when you are ready. You can view the result or download it in PDF format.",
    uploadExam: "Upload your exam or write your text:",
    takePhoto: "Take Photo",
    detectedText: "Detected text:",
    detectingText: "Detecting text from file or image...",
    resolvingExam: "Solving exam with our advanced AI...",
    containsMathOrGraphics: "The exam contains mathematical formulas or graphics",
    resolveExam: "Solve Exam",
    usageTips: "Usage Tips",
    examResult: "Exam result:",
    downloadingPDF: "Downloading PDF...",
    downloadPDF: "Download PDF",
    freeCreditsUsed: "You used your free credits",
    continueUsing: "Want to continue using PhotoExamAI? Discover the incredible benefits of our premium plans:",
    unlimitedAccess: "🚀 Unlimited access to exam resolutions.",
    prioritySupport: "💡 Priority support to help you when you need it most.",
    priorityProcessing: "🔐 Priority processing of your requests.",
    advancedTools: "🎓 Advanced tools for more effective learning.",
    additionalResources: "💼 Additional resources to prepare for your exams.",
    investInSuccess: "Invest in your academic success, choose a premium plan today and feel the difference!",
    viewPlans: "View plans 💲",
    tipsTitle: "Tips for using the platform",
    clearPhoto: "Clear photo: Make sure to take clear photos where the text is completely horizontal. This improves the accuracy with which the platform can interpret and process the information. Also, the image should not exceed 20Mb and the accepted formats are: 'png', 'jpeg', 'gif', 'webp'",
    repeatProcess: "Repeat the process: Don't hesitate to solve the same photo multiple times. Sometimes, the platform may offer slightly different results, so it's useful to do several tests to get the best answer.",
    mathReasoning: "Math reasoning: In math exams, it's crucial not to rely solely on automatic answers. Take the reasoning offered by the platform and then verify the calculations manually to ensure they are correct.",
    useTutors: "Use the tutors: If you have doubts about any answer, take advantage of the platform's tutor section. You can upload the exercise in question and ask all the questions you need to clarify your doubts and better understand the material.",
    close: "Close",
    chromeExtension: "New! 🚀 Download our Chrome extension",
    chromeExtensionDesc: "Solve your exams directly from any web page",
    downloadExtension: "Download extension",
    pdfTooLarge: "The PDF is too large. Please upload a PDF with 10 pages or less.",
    responseTypeLabel: "Response type:",
    responseTypeDetailed: "With detailed explanation",
    responseTypeBrief: "Result only",
    advancedOptions: "Advanced options",
    mathFormulasLabel: "Mathematical formulas or graphics",
    readAnswer: "Read answer",
    readingAnswer: "Reading...",
    premiumFeature: "This feature is for premium users only",
  }
};

  useEffect(() => {
    const hasRedirected = sessionStorage.getItem("hasRedirectedToPrecios");
    if (subscriptionType === "No Subscription" && !hasRedirected) {
      sessionStorage.setItem("hasRedirectedToPrecios", "true");
      navigate(currentLanguage === "es" ? "/ver-precios" : currentLanguage === "it" ? "/vedi-prezzi" : "/view-prices");
    }
  }, [subscriptionType, navigate, currentLanguage]);

  const connectExamSocket = (onFormResponse, onGenerationComplete) => {
    
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("Exam socket connected successfully.");
    });

    socket.on("form_response", (data) => {
      console.log("Received form_response");
      onFormResponse(data);
    });

    socket.on("form_end", (data) => {
      console.log("Form processing ended");
      onGenerationComplete(data);
      socket.disconnect();
    });

    socket.on("disconnect", () => {
      console.log("Exam socket disconnected.");
    });

    socket.on("error", (error) => {
      console.error("Exam socket error:", error);
    });

    return socket;
  };

  const connectTTSSocket = (onFormResponse, onGenerationComplete) => {
    console.log("Creating new TTS socket connection...");
    
    const socket = io(apiUrl, {
      reconnectionAttempts: 5,
      reconnectionDelay: 100,
      maxHttpBufferSize: 200 * 1024 * 1024,
      reconnectionDelayMax: 100,
    });

    socket.on("connect", () => {
      console.log("TTS socket connected successfully.");
    });

    socket.on("text_to_speech_chunk", (data) => {
      if (data?.audio_chunk) {
        onFormResponse(data);
      } else {
        console.warn("Received chunk without audio data:", data);
      }
    });

    socket.on("text_to_speech_end", (data) => {
      if (data?.success === false) {
        console.error("TTS ended with error:", data.message);
        onGenerationComplete({ error: data.message });
      } else {
        onGenerationComplete(data);
      }
      socket.disconnect();
    });

    socket.on("disconnect", () => {
      console.log("TTS socket disconnected.");
    });

    socket.on("error", (error) => {
      console.error("TTS socket error:", error);
    });

    return socket;
  };

  useEffect(() => {
    setExamData({
      ocrText: ocrText,
      response: examResult,
      lastOCR: new Date(),
    });
  }, [ocrText, examResult]);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        stripeModalRef.current &&
        !stripeModalRef.current.contains(event.target)
      ) {
        setIsStripeModalVisible(false);
      }
    }

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleImageChange = async (e) => {

    if (subscriptionType === "No Subscription") {
      setShowNoTokenModal(true);
      return;
    }

    if (subscriptionType?.includes("Trial") && remainingCredits === 0){
      document.getElementById('trial_ended_modal').showModal();
      return;
    }

    setIsLoadingOcr(true);
    const selectedFile = e.target.files[0];

    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoadingOcr(false);
      return;
    }

    try {
      if (selectedFile?.type === "application/pdf") {
        const pdf = await pdfjsLib.getDocument(URL.createObjectURL(selectedFile)).promise;

        if (pdf.numPages > 10) {
          setIsLoading(false);
          setIsLoadingOcr(false);
          alert(textContent[currentLanguage].pdfTooLarge);
          return;
        }

        setPdfPreview(URL.createObjectURL(selectedFile));
        setPdfUrl(selectedFile);

        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await fetch(apiUrl + "/api/easyexams/ocr", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          if (responseData.nowpay) {
            window.location.href = "https://www.fotoexamen.com/precios.html";
          } else {
            setIsLoadingOcr(false);
            const text = Array.isArray(responseData.texts) 
              ? responseData.texts.join(' ') 
              : responseData.texts;
            setOcrText(text);
          }
        } else {
          setIsLoadingOcr(false);
          alert(textContent.es.imageTextDetectionFailed);
        }
      } else if (selectedFile?.type.startsWith("image/")) {
        setPdfUrl(null);
        setPdfPreview(null);
        setImage(URL.createObjectURL(selectedFile));
        setImagebin(selectedFile);
        if (selectedFile.type === "application/pdf") {
          setPdfPreview(URL.createObjectURL(selectedFile));
        }

        const formData = new FormData();
        formData.append("file", selectedFile);
        const response = await fetch(apiUrl + "/api/easyexams/ocr", {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
          },
          body: formData,
        });
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.nowpay) {
            window.location.href = "https://www.fotoexamen.com/precios.html";
          } else {
            setIsLoadingOcr(false);
            const text = Array.isArray(responseData.texts) 
              ? responseData.texts.join(' ') 
              : responseData.texts;
            setOcrText(text);
          }
        } else {
          setIsLoadingOcr(false);
          alert(
            textContent.es.imageTextDetectionFailed
          );
        }
      } else {
        setIsLoadingOcr(false);
        setPdfPreview(null); // Limpia la vista previa del PDF
        setPdfUrl(null);     // Limpia la URL del PDF
        setImage(null);      // Limpia la imagen cargada
        setImagebin(null);
      }
    } catch (error) {
      console.error("Error processing the file:", error);
      setIsLoadingOcr(false);
    }
  };

  const waitForSocketConnection = (socket) => {
    return new Promise((resolve, reject) => {
      const maxAttempts = 5;
      let attempts = 0;

      const checkSocketConnection = () => {
        if (socket.connected) {
          resolve();
        } else {
          attempts += 1;
          if (attempts >= maxAttempts) {
            reject(new Error("Socket connection failed"));
          } else {
            setTimeout(checkSocketConnection, 200);
          }
        }
      };

      checkSocketConnection();
    });
  };

  const handleResolveExam = async () => {
    setIsLoading(true);
    if (!token) {
      setShowNoTokenModal(true);
      console.error("No authentication token found.");
      setIsLoading(false);
      return;
    }

    socketRef.current = connectExamSocket(
      (data) => {
        messageCount.current += 1;
        if (messageCount.current % 100 === 0) {
          setExamResult(data?.form_response);
        }
        if (data?.thinking) {
          setExamResult(data?.thinking);
        }
      },
      (data) => {
        setExamResult(data?.form_end);
        setIsLoading(false);
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
      refreshSubscription();
    } catch (error) {
      alert(
        textContent.es.connectionFailed
      );
      setIsLoading(false);
      return;
    }

    const data = {
      token: token,
      form: {
        ocrText: ocrText,
        containsMathOrGraphics: containsMathOrGraphics,
        responseType: responseType,
      },
      files: {},
    };

    if (imagebin) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1];
        data.files.image = base64Data;
        socketRef.current.emit("form", data);
      };
      reader.readAsDataURL(imagebin);
    } else if (pdfUrl) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const base64Data = e.target.result.split(",")[1];
        data.files.pdf = base64Data;
        socketRef.current.emit("form", data);
      };
      reader.readAsDataURL(pdfUrl);
    } else {
      socketRef.current.emit("form", data);
    }
  };

  const showStripeModal = () => {
    setIsStripeModalVisible(true);
  };

  const toggleTipsModal = () => {
    setShowTipsModal(!showTipsModal);
  };

  const handleTextToSpeech = async () => {
    setIsPlaying(true);
    
    // Si ya tenemos el audio procesado, reproducirlo directamente
    if (processedAudio) {
      try {
        audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
        audioSourceRef.current = audioContextRef.current.createBufferSource();
        audioSourceRef.current.buffer = processedAudio;
        audioSourceRef.current.connect(audioContextRef.current.destination);
        
        audioSourceRef.current.onended = () => {
          setIsPlaying(false);
          audioSourceRef.current = null;
        };
        
        audioSourceRef.current.start(0);
        return;
      } catch (error) {
        console.error("Error replaying audio:", error);
        setIsPlaying(false);
        setProcessedAudio(null);
      }
    }

    let localAudioChunks = [];
    let totalChunks = 0;
    
    if (audioSourceRef.current) {
      audioSourceRef.current.stop();
    }
    if (audioContextRef.current) {
      await audioContextRef.current.close();
      audioContextRef.current = null;
    }

    socketRef.current = connectTTSSocket(
      async (data) => {
        if (data?.audio_chunk) {
          try {
            const binaryString = window.atob(data.audio_chunk);
            const bytes = new Uint8Array(binaryString.length);
            for (let i = 0; i < binaryString.length; i++) {
              bytes[i] = binaryString.charCodeAt(i);
            }
            
            // Almacenar el chunk en el índice correcto
            localAudioChunks[data.chunk_index] = bytes.buffer;
            totalChunks = data.total_chunks;
          } catch (error) {
            console.error("Error processing audio chunk:", error);
          }
        }
      },
      async (data) => {
        if (data?.error) {
          console.error("Text-to-speech error:", data.error);
          setIsPlaying(false);
          alert(textContent[currentLanguage].audioError);
          return;
        }

        try {
          // Verificar que tenemos todos los chunks
          if (localAudioChunks.length !== totalChunks || localAudioChunks.includes(undefined)) {
            throw new Error("Missing audio chunks");
          }

          // Combinar todos los chunks en un solo ArrayBuffer
          const totalLength = localAudioChunks.reduce((acc, chunk) => acc + chunk.byteLength, 0);
          const combinedChunks = new Uint8Array(totalLength);
          
          let offset = 0;
          localAudioChunks.forEach(chunk => {
            combinedChunks.set(new Uint8Array(chunk), offset);
            offset += chunk.byteLength;
          });

          audioContextRef.current = new (window.AudioContext || window.webkitAudioContext)();
          
          const audioBuffer = await audioContextRef.current.decodeAudioData(combinedChunks.buffer);
          setProcessedAudio(audioBuffer);
          
          audioSourceRef.current = audioContextRef.current.createBufferSource();
          audioSourceRef.current.buffer = audioBuffer;
          audioSourceRef.current.connect(audioContextRef.current.destination);
          
          audioSourceRef.current.onended = () => {
            setIsPlaying(false);
            audioSourceRef.current = null;
          };
          
          audioSourceRef.current.start(0);
        } catch (error) {
          console.error("Error processing audio:", error);
          setIsPlaying(false);
          setProcessedAudio(null);
          alert(textContent[currentLanguage].audioError);
        }
      }
    );

    try {
      await waitForSocketConnection(socketRef.current);
      
      socketRef.current.emit("text_to_speech", {
        token: token,
        text: examResult,
        format: 'mp3'
      });
    } catch (error) {
      console.error("Error in text-to-speech setup:", error);
      setIsPlaying(false);
      alert(textContent[currentLanguage].streamError);
    }
  };

  useEffect(() => {
    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []);

  useEffect(() => {
    setProcessedAudio(null);
  }, [examResult]);

  const handleHideExtensionCard = () => {
    setShowExtensionCard(false);
    localStorage.setItem('hideExtensionCard', 'true');
  };

  return (
    <div className="container mx-auto p-5 rounded-md shadow-lg mb-20 mt-4">
      <CurrentPath text={textContent[currentLanguage].currentPath} />
      <div className="container max-w-4xl mx-auto p-8 bg-white rounded">
        <div className="mb-8 text-center max-w-xl mx-auto">
          <h1 className="text-4xl font-bold mb-6 border-b-4 border-white pb-2">
            {textContent[currentLanguage].title}
          </h1>
          <p>
            {textContent[currentLanguage].description}
          </p>
        </div>

        {currentLanguage === 'es' && !mobile && showExtensionCard && (
          <div className="card bg-base-100 shadow-lg mb-6">
            <div className="card-body p-4">
              <button 
                onClick={handleHideExtensionCard}
                className="absolute top-2 left-2 btn btn-ghost btn-sm btn-circle"
              >
                <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
              <div className="flex flex-col sm:flex-row items-center justify-between gap-4">
                <div className="flex items-center gap-3">
                  <div className="bg-info/10 p-2 rounded-full">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" className="stroke-info w-6 h-6">
                      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                    </svg>
                  </div>
                  <div>
                    <h3 className="font-bold text-lg">{textContent[currentLanguage].chromeExtension}</h3>
                    <p className="text-sm text-base-content/70">{textContent[currentLanguage].chromeExtensionDesc}</p>
                  </div>
                </div>
                <a 
                  href="https://chromewebstore.google.com/detail/fotoexamen-resuelve-tus-e/limjglkioeenlejbdikfeeecgmigbmmd"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn btn-primary btn-sm normal-case"
                >
                  {textContent[currentLanguage].downloadExtension}
                </a>
              </div>
            </div>
          </div>
        )}

        <div className="mb-8 space-y-6">
          <h2 className="text-2xl font-bold mb-4">{textContent[currentLanguage].uploadExam}</h2>
          <div className="flex flex-col sm:flex-row justify-center items-center space-y-4 sm:space-y-0 sm:space-x-4">
            <input
              type="file"
              accept="image/*,application/pdf"
              capture="environment"
              className="hidden"
              onChange={handleImageChange}
              id="photo-capture"
            />
            <label
              htmlFor="photo-capture"
              className="btn btn-primary"
            >
              <FontAwesomeIcon icon={faCamera} className="fa-lg mr-2" />
              {textContent[currentLanguage].takePhoto}
            </label>

            <input
              type="file"
              accept="image/*,application/pdf"
              onChange={handleImageChange}
              className="file-input file-input-bordered w-full max-w-xs"
            />
          </div>
        </div>

        {(image || pdfPreview) && (
          <div className="flex items-center justify-center mb-16">
            <dialog id="previewModal" className="modal">
              <div className="modal-box">
                {image && !pdfPreview && (
                  <img
                    src={image}
                    alt="Uploaded exam"
                    className="max-w-full h-auto"
                  />
                )}
                {pdfPreview && (
                  <iframe
                    src={pdfPreview}
                    title="PDF Preview"
                    className="w-full h-96"
                  ></iframe>
                )}
                <div className="modal-action">
                  <button
                    className="btn btn-primary"
                    onClick={() =>
                      document.getElementById("previewModal").close()
                    }
                  >
                    {textContent[currentLanguage].close}
                  </button>
                </div>
              </div>
            </dialog>
            <div
              onClick={() =>
                document.getElementById("previewModal").showModal()
              }
              className="btn mt-3 rounded h-64 w-full object-cover object-top flex items-center justify-center overflow-hidden"
              style={{ background: "#f0f0f0" }}
            >
              {image && !pdfPreview ? (
                <img
                  src={image}
                  alt="Uploaded exam"
                  className="max-w-full h-auto"
                />
              ) : (
                <FaFilePdf size={64} color="#ff0000" />
              )}
            </div>
          </div>
        )}

          <div className="mb-8">
{/*             <h2 className="text-2xl font-bold mb-4">{textContent[currentLanguage].detectedText}</h2>
 */}            <textarea
              className="w-full h-24 border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line"
              value={ocrText}
              onChange={(e) => setOcrText(e.target.value)}
              disabled={isLoadingOcr || isLoading}
            ></textarea>
          </div>

        {isLoadingOcr && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              {textContent[currentLanguage].detectingText}
            </p>
          </div>
        )}
        {isLoading && (
          <div className="flex flex-col justify-center items-center mb-4">
            <div className="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-blue-600 my-4"></div>
            <p className="text-lg font-semibold">
              {textContent[currentLanguage].resolvingExam}
            </p>
          </div>
        )}
        <div className="divider" />
        <div className="mb-8">
          <div className="flex flex-col gap-6">
            {/* Advanced Options Collapsible Card */}
            <div className="card bg-base-100 shadow-lg">
              <div 
                className="card-title p-3 cursor-pointer flex justify-between items-center"
                onClick={() => setShowAdvancedOptions(!showAdvancedOptions)}
              >
                <h3 className="text-sm font-medium">{textContent[currentLanguage].advancedOptions}</h3>
                <svg 
                  className={`w-4 h-4 transition-transform ${showAdvancedOptions ? 'rotate-180' : ''}`} 
                  fill="none" 
                  stroke="currentColor" 
                  viewBox="0 0 24 24"
                >
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7" />
                </svg>
              </div>
              
              {/* Contenido desplegable */}
              <div className={`card-body p-3 ${showAdvancedOptions ? '' : 'hidden'}`}>
                {/* Response Type toggle */}
                <div className="form-control w-full">
                  <div className="flex items-center justify-between p-2">
                    <div className="flex flex-col">
                      <span className="text-sm font-medium">{textContent[currentLanguage].responseTypeLabel}</span>
                      <span className="text-xs text-gray-600">
                        {responseType === "detailed" ? textContent[currentLanguage].responseTypeDetailed : textContent[currentLanguage].responseTypeBrief}
                      </span>
                    </div>
                    <input
                      type="checkbox"
                      className="toggle toggle-primary toggle-sm"
                      checked={responseType === "detailed"}
                      onChange={(e) => setResponseType(e.target.checked ? "detailed" : "brief")}
                    />
                  </div>
                </div>

                {/* Math/Graphics toggle */}
                <div className="form-control w-full">
                  <div className="flex items-center justify-between p-2">
                    <span className="text-sm font-medium">
                      {textContent[currentLanguage].mathFormulasLabel}
                    </span>
                    <input
                      type="checkbox"
                      className="toggle toggle-primary toggle-sm"
                      checked={containsMathOrGraphics}
                      onChange={(e) => setContainsMathOrGraphics(e.target.checked)}
                    />
                  </div>
                </div>
              </div>
            </div>

            {/* Buttons */}
            <div className="flex flex-col items-center space-y-4 md:flex-row md:space-y-0 md:space-x-4">
              <button
                className={`btn btn-primary ${
                  (!ocrText.trim() || isLoading) && "opacity-50 cursor-not-allowed"
                }`}
                onClick={handleResolveExam}
                disabled={!ocrText.trim() || isLoading}
              >
                {textContent[currentLanguage].resolveExam}
              </button>
              <button
                onClick={toggleTipsModal}
                className="btn btn-outline"
              >
                {textContent[currentLanguage].usageTips}
              </button>
            </div>
          </div>

          {examResult !== "" && (
            <div className="mt-8">
              <h2 className="text-2xl font-bold mb-4">{textContent[currentLanguage].examResult}</h2>
              <div className="flex flex-wrap gap-2 mb-4">
                <button
                  onClick={handleTextToSpeech}
                  disabled={isPlaying}
                  className="btn btn-primary"
                >
                  {isPlaying ? (
                    <>
                      <span className="loading loading-spinner"></span>
                      {textContent[currentLanguage].readingAnswer}
                    </>
                  ) : (
                    <>
                      <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5 mr-2" viewBox="0 0 20 20" fill="currentColor">
                        <path fillRule="evenodd" d="M9.383 3.076A1 1 0 0110 4v12a1 1 0 01-1.707.707L4.586 13H2a1 1 0 01-1-1V8a1 1 0 011-1h2.586l3.707-3.707a1 1 0 011.09-.217zM14.657 2.929a1 1 0 011.414 0A9.972 9.972 0 0119 10a9.972 9.972 0 01-2.929 7.071 1 1 0 01-1.414-1.414A7.971 7.971 0 0017 10c0-2.21-.894-4.208-2.343-5.657a1 1 0 010-1.414zm-2.829 2.828a1 1 0 011.415 0A5.983 5.983 0 0115 10a5.984 5.984 0 01-1.757 4.243 1 1 0 01-1.415-1.415A3.984 3.984 0 0013 10a3.983 3.983 0 00-1.172-2.828 1 1 0 010-1.415z" clipRule="evenodd" />
                      </svg>
                      {textContent[currentLanguage].readAnswer}
                    </>
                  )}
                </button>
              </div>
              <div className="border-2 border-gray-300 p-4 rounded-lg whitespace-pre-line" id="result">
                <MarkdownPreview content={examResult} />
              </div>
            </div>
          )}
        </div>
        {isStripeModalVisible && (
          <div
            id="stripeModal"
            ref={stripeModalRef}
            className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4"
          >
            <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
              <div className="modal fixed inset-0 z-50 bg-black bg-opacity-50 flex items-center justify-center p-4">
                <div className="modal-content bg-white p-2 sm:p-6 rounded-lg shadow-lg text-center overflow-auto max-h-full">
                  <h2 className="text-lg sm:text-xl font-bold mb-4">
                    {textContent[currentLanguage].freeCreditsUsed}
                  </h2>
                  <p className="mb-4 text-sm sm:text-base">
                    {textContent[currentLanguage].continueUsing}
                  </p>
                  <ul className="list-disc list-inside text-left mb-4 text-sm sm:text-base">
                    <li className="mb-2">
                      {textContent[currentLanguage].unlimitedAccess}
                    </li>
                    <li className="mb-2">
                      {textContent[currentLanguage].prioritySupport}
                    </li>
                    <li className="mb-2">
                      {textContent[currentLanguage].priorityProcessing}
                    </li>
                    <li className="mb-2">
                      {textContent[currentLanguage].advancedTools}
                    </li>
                    <li className="mb-2">
                      {textContent[currentLanguage].additionalResources}
                    </li>
                  </ul>
                  <p className="mb-4 font-bold text-sm sm:text-base">
                    {textContent[currentLanguage].investInSuccess}
                  </p>
                  <button
                    id="view-prices"
                    className="bg-green-500 hover:bg-green-700 text-white font-bold py-3 px-4 rounded transition duration-300 ease-in-out shadow-lg transform hover:scale-105"
                    onClick={showStripeModal}
                  >
                    {textContent[currentLanguage].viewPlans}
                  </button>
                </div>
              </div>

              <button onClick={showStripeModal}>{textContent[currentLanguage].viewPlans}</button>
            </div>
          </div>
        )}

        {showTipsModal && (
          <div className="fixed inset-0 z-50 overflow-auto bg-smoke-light flex">
            <div className="relative p-8 bg-white w-full max-w-lg m-auto flex-col flex rounded-lg shadow-lg">
              <div className="flex justify-between items-center pb-3">
                <p className="text-2xl font-bold">
                  {textContent[currentLanguage].tipsTitle}
                </p>
                <div className="cursor-pointer z-50" onClick={toggleTipsModal}>
                  <svg
                    className="fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="18"
                    viewBox="0 0 18 18"
                  >
                    <path d="M12.3 11.7l-3.3-3.3 3.3-3.3L11.7 5l-3.3 3.3L5 5 4.3 5.7l3.3 3.3-3.3 3.3L5 12.3l3.3-3.3 3.3 3.3z" />
                  </svg>
                </div>
              </div>
              <ul className="list-disc list-inside text-left text-md lg:text-lg mb-4 leading-relaxed">
                <li>
                  {textContent[currentLanguage].clearPhoto}
                </li>
                <li>
                  {textContent[currentLanguage].repeatProcess}
                </li>
                <li>
                  {textContent[currentLanguage].mathReasoning}
                </li>
                <li>
                  {textContent[currentLanguage].useTutors}
                </li>
              </ul>
              <div className="flex justify-end pt-2">
                <button
                  className="btn btn-primary"
                  onClick={toggleTipsModal}
                >
                  {textContent[currentLanguage].close}
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
      {!token && !mobile && <SEOExamSolver/>}
    </div>
  );};

export default ExamSolver;
