import { useState } from 'react';
export function useLanguage() {
  const [currentLanguage, setCurrentLanguage] = useState(() => {
    return process.env.REACT_APP_LANG;
  });

  const changeLanguage = (newLanguage) => {
    console.log('Changing language to:', newLanguage);
    setCurrentLanguage(newLanguage);
  };

  return {
    currentLanguage,
    changeLanguage,
  };
}
